<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png" /> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
    <div class="main">
      <div class="header">
        <div class="logo">
          <img class="logo" src="@/assets/images/logo.d491a9b8.jpeg" />
          <a href="https://www.decathlon.pl/sporty/rowery/rowery" target="_blank" class="logohref"><img class="logo" src="@/assets/images/logo_dacathlon.svg" /></a>
        </div>
        <button
          v-on:click="menutoggle"
          id="hamburgero"
          class="hamburger hamburger--collapse"
          type="button"
        >
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </div>
    </div>
    <div class="menu-wrapper">
      <div id="menu" class="menu">
          <ul>
            <li>
              <a href="https://www.arval.pl/wynajem-konsumencki" target="_blank"
                >Dla Konsumenta</a
              >
            </li>
            <li>
              <a
                href="https://www.arval.pl/oferta-msp/wynajem-dlugoterminowy"
                target="_blank"
                >Oferta MŚP</a
              >
            </li>
            <li>
              <a
                href="https://www.arval.pl/oferta-dla-korporacji"
                target="_blank"
                >Oferta dla Korporacji</a
              >
            </li>
            <li>
              <a
                href="https://www.arval.pl/ogolne/elektromobilnosc"
                target="_blank"
                >Elektromobilność</a
              >
            </li>
            <li>
              <a href="https://www.arval.pl/partnerzy-1" target="_blank"
                >Partnerzy</a
              >
            </li>
            <li>
              <a
                href="https://www.arval.pl/klient/samochody-poleasingowe-w-arval"
                target="_blank"
                >Auta poleasingowe</a
              >
            </li>
            <li>
              <a href="http://www.cok-arval.pl/" target="_blank"
                ><img src="@/assets/images/human.svg" alt=""
              /></a>
            </li>
          </ul>
        </div>
    </div>
    <div class="hero">
      <div class="container">
        <div class="slogan">
          <h2>Wybierz model idealny dla Ciebie</h2>
        </div>
        <div class="subslogan">
          <span v-on:click="scrollToCalc"
            >i samodzielnie oblicz ratę wynajmu roweru<br>
w Arval Service Lease Polska
</span
          >
        </div>
      </div>
    </div>
    <div class="subhero">
      <div class="container">
        <div class="row">
          <div class="column wider">
            <h3>
              ARVAL BIKE LEASE czyli wynajem rowerów tradycyjnych i
              elektrycznych
            </h3>
            <p>
              ARVAL BIKE LEASE to kompleksowe rozwiązanie dla klientów poszukujących alternatywnych możliwości w zakresie mobilności i udostępnienia rowerów firmowych swoim pracownikom. Dodatkowo rower to sportowa i zdrowa opcja, dzięki której zmniejsza się ślad CO<sub>2</sub> organizacji. Oferta ARVAL zawiera finansowanie rowerów elektrycznych i klasycznych dostępnych w Decathlon, wraz z ubezpieczeniem i pakietem serwisowym.
            </p>
            <div class="howto">
              <h3>Jak uzyskać ofertę?</h3>
              <ol>
                <li>
                  <span>
                    <a
                      target="_blank"
                      ref="nofollow"
                      href="https://www.decathlon.pl/sporty/rowery/rowery"
                    ><button class="brsd">SPRAWDŹ OFERTĘ</button></a>
                     na stronie naszego <a
                      target="_blank"
                      ref="nofollow"
                      href="https://www.decathlon.pl/sporty/rowery/rowery" style="color: #008352;"
                    >Partnera</a>
                    <!-- <a
                      target="_blank"
                      ref="nofollow"
                      href="https://www.centrumrowerowe.pl/rowery/.oxfeld,romet,ecobike,mongoose,unity,tabou/"
                    >
                      <img
                        class="logo-centrum"
                        src="@/assets/images/logo_centrum.svg"
                      />
                    </a> -->
                    <!-- <a
                      target="_blank"
                      ref="nofollow"
                      href="https://www.decathlon.pl/sporty/rowery/rowery"
                    >
                      <img
                      width="160"
                        class="logo-centrum"
                        src="@/assets/images/logo_dacathlon.svg"
                      />
                    </a> -->
                    i skonfiguruj swój idealny rower.
                  </span>
                </li>
                <li>
                  <span>
                    Wklej do <span class="greentxt">kalkulatora</span> link do
                    wybranego modelu wraz z ceną brutto i sprawdź ile wyniesie
                    rata.
                  </span>
                </li>
                <li>
                  <span>
                    Jeśli jesteś zainteresowany wynajmem
                    <span class="greentxt"
                      >pobierz ofertę i skontaktuj się z nami.</span
                    >
                  </span>
                </li>
              </ol>
            </div>
          </div>
          <div class="column form" id="anchor">
            <form>
              <h3>Oblicz ratę roweru</h3>
              <div
                v-if="debugMode"
                style="background-color: white; width: 100%; padding: 10px"
              >
                bruttoprice: {{ this.bikeprice }}<br />
                nettoprice: {{ nettoprice }}<br />
                udzielony rabat: {{ this.rabat }}<br />
                RV po 36 msc: {{ this.rv }}<br />
                funding: {{ funding }}<br />
                finMargin: {{ finMargin }}<br />
                clientRate: {{ clientRate }}<br />
                managementFee: {{ managementFee }}<br />
                months: {{ months }}<br />
                thiefProtection: {{ thiefProtection }}<br />
                service: {{ service }}<br />
                countedService: {{ countedService }}<br />
                thiefProtection: {{ thiefProtection }}<br />
                pricewithrabat: {{ pricewithrabat }}<br />
                finalcount: {{ finalcount }}<br />
                insurance (Ubezpieczenie TUV): {{ insurance }}<br />
                countedInsurance: {{ countedInsurance }}<br />
                financialRent (czynsz finansowy): {{ financialRent }}<br />
                installmentMonth: {{ installmentMonth }}<br />
                <br /><br /><br />
                zestawienie cen składowych:<br>
                Czynsz finansowy: {{ financialRent }}<br />
                Serwis: {{ countedService }}<br />
                Ubezpieczenie: {{  Math.round(insurance/months)  }}<br />
                Management Fee: {{ managementFee }}<br />
                <br /><br />
                Podsuma: {{ parseFloat(financialRent)+parseFloat(countedService)+parseFloat((Math.round(insurance/months)))+parseFloat(managementFee) }}<br />
                
              </div>
              <label for="bike_model"
                >Wklej link do wybranego modelu roweru</label
              >
              <input v-model="bikemodel" type="text" name="bike_model" />

              <label for="price">Wpisz cenę detaliczną brutto zł</label>
              <input v-model="bikeprice" type="number" name="price" />

              <!-- <label for="bike_type">Wybierz typ roweru</label>
              <select v-model="biketype" name="bike_type" id="bike_type">
                <option value="electric">Elektryczny</option>
                <option value="regular">Klasyczny</option>
              </select> -->

              <h4>Twoja rata</h4>

              <div class="subrow">
                <div class="subcolumn">
                  <label for="r_netto_month">Rata netto zł / miesiąc</label>
                  <input
                    disabled
                    v-model="this.installmentMonth"
                    type="text"
                    name="r_netto_month"
                  />
                </div>
                <div class="subcolumn">
                  <label for="r_netto_daily">Rata netto zł / dzień</label>
                  <input
                    disabled
                    v-model="this.installmentDay"
                    type="text"
                    name="r_netto_daily"
                  />
                </div>
              </div>

              <div class="subrow">
                <div class="subcolumn">
                  <label for="r_brutto_month">Rata brutto zł / miesiąc</label>
                  <input
                    disabled
                    type="text"
                    v-model="this.installmentBruttoMonth"
                    name="r_brutto_month"
                  />
                </div>
                <div class="subcolumn">
                  <label for="r_brutto_daily">Rata brutto zł / dzień</label>
                  <input
                    disabled
                    type="text"
                    v-model="this.installmentBruttoDay"
                    name="r_brutto_daily"
                  />
                </div>
              </div>

              <span class="disclaimer"
                >Niniejsza informacja nie jest ofertą w rozumieniu Kodeksu
                Cywilnego.</span
              >
              <span class="disclaimer second"
                >Ostateczna rata wynajmu zostanie potwierdzona w przesłanej
                ofercie po potwierdzeniu zdolności kredytowej
                Wnioskodawcy.</span
              >
              <!-- <div class="subcolumn"></div>
              <div class="subcolumn"></div> -->
            </form>
            <div class="buttons">
              <div class="subrow">
                <div class="subcolumn">
                  <button
                    :class="this.disabled ? 'disabled' : ''"
                    :disabled="disabled"
                    class="white mycustombutton"
                    v-on:click="generateReport"
                  >
                    Pobierz ofertę
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="section_info">
          <div class="boxed">
            <h3>Co zawiera oferta?</h3>
            <div class="row">
              <div class="column listing">
                <span
                  ><img class="micon" src="@/assets/images/s1.svg" /><span
                    >Finansowanie rowerów elektrycznych i klasycznych</span
                  ></span
                >
                <span
                  ><img class="micon" src="@/assets/images/s2.svg" /><span
                    >Przeglądy okresowe i serwis w trakcie trwania umowy</span
                  ></span
                >
                <span
                  ><img class="micon" src="@/assets/images/s3.svg" /><span
                    >Ubezpieczenie od szkód i kradzieży oraz OC i NNW
                    użytkownika</span
                  ></span
                >
                <!-- <ul>
                  <li>Finansowanie rowerów elektrycznych i klasycznych</li>
                  <li>Przeglądy okresowe i serwis w trakcie trwania umowy</li>
                  <li>Przeglądy okresowe i serwis w trakcie trwania umowy</li>
                  <li>Przeglądy okresowe i serwis w trakcie trwania umowy</li>
                  <li>Zapięcie przeciwkradzieżowe typu Ulock</li>
                </ul> -->
              </div>
              <div class="column listing">
                <span
                  ><img class="micon" src="@/assets/images/s4.svg" /><span
                    >Umowa wynajmu na 36 miesięcy</span
                  ></span
                >
                <span
                  ><img class="micon" src="@/assets/images/s5.svg" /><span
                    >Opcja wykupu roweru na koniec umowy przez
                    pracownika</span
                  ></span
                >
                <span class="greentxt"
                  ><img class="micon" src="@/assets/images/s6.svg" /><span
                    >W pakiecie dodatkowe zapięcie przeciwkradzieżowe</span
                  ></span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section_cta">
      <div class="container">
        <div class="row">
          <div class="column">
            <h3 class="dark">Zainteresowany ofertą?</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="section_contact">
      <div class="container">
        <div class="row">
          <div class="column">
            <div class="green-box">
              <h2>Zainteresowany ofertą?</h2>
              <button class="formopen" @click="toggleForm">Wypełnij formularz</button>
              <span>aby zamówić rozmowę z naszym konsultantem.</span>
            </div>
          </div>
        </div>
        <FormComponent v-if="showForm"></FormComponent>
      </div>
    </div>
  
    <div class="footer">
      <div class="container">
        <div class="fnav">
          <div class="menu">
            <ul>
              <li>
                <a href="https://www.arval.pl/ogolne/polityka-plikow-cookie"
                  >Polityka plików cookie</a
                >
              </li>
              <li>
                <a href="https://www.arval.pl/ogolne/polityka-danych-osobowych"
                  >Polityka Danych Osobowych</a
                >
              </li>
              <li><a href="https://www.arval.pl/sitemap">Sitemap</a></li>
              <li>
                <a href="https://www.arval.pl/nota-prawna-1">Nota prawna</a>
              </li>
              <li>
                <a
                  href="https://www.arval.pl/sites/default/files/146/2021/12/ARVAL_PL_strategia_podatkowa_2020_do_publikacji.pdf"
                  >Strategia podatkowa 2020</a
                >
              </li>
              <li>
                <a href="https://www.arval.pl/public/whistle-blowing"
                  >Whistle-blowing</a
                >
              </li>
              <li>
                <a href="https://www.arval.pl/privacy-form">Privacy form</a>
              </li>
              <li>
                <a href="https://www.arval.pl/klient/formularz-reklamacyjny"
                  >Formularz reklamacyjny</a
                >
              </li>
            </ul>
          </div>
          <div class="socials">
            <ul>
              <li>
                <a href="https://www.facebook.com/ArvalPolska/" target="_blank">
                  <img class="social" src="@/assets/images/fb.svg"
                /></a>
              </li>
              <li>
                <a href="https://www.youtube.com/c/arvalpolska" target="_blank"
                  ><img class="social" src="@/assets/images/yt.svg"
                /></a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/bnp-paribas-arval/"
                  target="_blank"
                  ><img class="social" src="@/assets/images/li.svg"
                /></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- PDF -->
    <div class="pdf">
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      :enableLinks="true"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      ref="html2Pdf"
      filename="oferta"
      :htmlToPdfOptions="filen"
    >
      <section slot="pdf-content">
        <div class="content">
          <div class="pageb">
            <!-- <img
              class="pagebck"
              src="~@/assets/images/pdf/page_background.svg"
            /> -->
          </div>
          <div class="pagea">
            <!-- <img
              style="width: 100%; height: auto;"
              class="page"
              src="~@/assets/images/pdf/newpdfpage.jpg"
            /> -->
          </div>
        </div>
        <div class="datainfo">
          <div class="biketype">
            <!-- {{this.bikemodel}} -->
            <a style="display: block; font-weight: 100 !important" :href="this.bikemodel ? checkurl(this.bikemodel) : 'https://google.pl'"
              >{{ this.bikemodel ? shorturl(checkurl(this.bikemodel), 45) : 'https://google.pl' }}</a
            >
          </div>
          <div class="bikedetalprice">
            {{ this.bikeprice ? this.bikeprice : "-" }}
          </div>

          <div class="nettomonth">
            {{ this.installmentMonth ? this.installmentMonth : "-" }}
          </div>

          <div class="nettoday">
            {{ this.installmentDay ? this.installmentDay : "-" }}
          </div>

          <div class="bruttomonth">
            {{
              this.installmentBruttoMonth ? this.installmentBruttoMonth : "-"
            }}
          </div>
          <div class="bruttoday">
            {{ this.installmentBruttoDay ? this.installmentBruttoDay : "-" }}
          </div>
        </div>
      </section>
    </vue-html2pdf>
  </div>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import FormComponent from "./components/FormComponent.vue";
import "../public/scss/main.scss";
export default {
  name: "App",
  data: function () {
    return {
      showForm: false,
      bikemodel: "",
      bikeprice: "",
      biketype: "",
      // service: 700,
      preparingBike: 100,
      // thiefProtection: 100,
      funding: 7.21 / 100,
      finMargin: 2 / 100,
      clientRate: (9.21 / 100).toFixed(4),
      months: 36,
      managementFee: 5,
      rv: (20 / 100),
    };
  },
  components: {
    VueHtml2pdf,
    FormComponent
  },
  watch: {},
  computed: {
    // Obliczenia
    // 
    debugMode: function () {
      let urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get("debug") === "true") {
        return true;
      } else {
        return false;
      }
    },
    thiefProtection: function () {
      // nettoprice;
      if (this.nettoprice <= 5000) {
        return 150;
      } else if (this.nettoprice > 5000) {
        return 300;
      } else {
        return 150;
      }
    },

   
    service: function () {
      if (this.bikeprice < 5000) {
        return 800;
      } else if (this.bikeprice >= 5000 && this.bikeprice <= 10000) {
        return 1200;
      } else if (this.bikeprice > 10000) {
        return 1800;
      } else {
        return 800;
      }
    },

    filen: function () {
      return {
        enableLinks: true,
        filename: "oferta.pdf",
        image: {
        type: 'png', 
        quality: 1,
        },
        html2canvas: {
        scale: 2,
    },
      };
    },
    disabled: function () {
      if (this.bikeprice) {
        return false;
      } else {
        return true;
      }
    },
    nettoprice: function () {
      let nettoprice = this.bikeprice / 1.23;

      let newnettoprice =
        Math.round((parseFloat(nettoprice) + Number.EPSILON) * 100) / 100;
      return newnettoprice;
    },
    // Rabat (0 %)
    rabat: function () {
      // `this` points to the vm instance
      // return this.biketype == "electric" ? 0 : 0;
      // price rabat was removed so we return 0;
      return "0";
    },
    pricewithrabat: function () {
      // price rabat was removed so we return price without rabat
      let pricerabat = 0;
      if (this.rabat) {
        if (this.rabat == 10) {
          pricerabat = this.nettoprice.toFixed(2);
        } else if (this.rabat == 15) {
          pricerabat = this.nettoprice.toFixed(2);
        } else {
          pricerabat = this.nettoprice.toFixed(2);
        }
      }
      let newprice =
        Math.round((parseFloat(pricerabat) + Number.EPSILON) * 100) / 100;
      // return pricerabat.toFixed(2);
      return newprice;
    },
    finalcount: function () {
      let rv36 = (this.rv * parseFloat(this.nettoprice)).toFixed(2);
      return rv36;
    },
    investment: function () {
      let investment =
        this.pricewithrabat - this.finalcount + this.preparingBike;
      return investment;
    },
    insurance: function () {
      // Percentage calc
      let perc = 5.9 / 100;
      // if (this.pricewithrabat < 5000) {
      //   perc = 9 / 100;
      // } else {
      //   perc = (10.5 / 100).toFixed(3);
      // }
      let calc = (this.pricewithrabat * perc + 180) * 3;
      // console.log(19.8/100)
      // console.log(perc)
      let insurance = calc.toFixed(2);
      return insurance;
    },
    financialRent: function () {
      let rate_per_period = parseFloat(this.clientRate / 12);
      let number_of_payments = parseFloat(this.months);
      let present_value = parseFloat(
        -this.pricewithrabat - this.preparingBike - this.thiefProtection
      );
      let future_value = parseFloat(this.finalcount);
      let type = 0;
      let financialRent = this.num_pmt(
        rate_per_period,
        number_of_payments,
        present_value,
        future_value,
        type
      );
      return financialRent.toFixed(1);
    },
    countedService: function () {
      let countedService = parseFloat(this.service) / parseFloat(this.months);
      return countedService.toFixed(1);
    },
    countedInsurance: function () {
      let countedInsurance =
        parseFloat(this.insurance) / parseFloat(this.months);
      return countedInsurance.toFixed(2);
    },
    installmentMonth: function () {
      let installmentMonth =
        parseFloat(this.financialRent) +
        parseFloat(this.countedService) +
        parseFloat(this.countedInsurance) +
        parseFloat(this.managementFee);

      if (this.bikeprice) {
        return installmentMonth.toFixed(2);
      } else {
        return "";
      }
    },
    installmentDay: function () {
      let installmentDay = parseFloat(this.installmentMonth) / 30;

      if (this.bikeprice) {
        return installmentDay.toFixed(2);
      } else {
        return "";
      }
    },
    installmentBruttoDay: function () {
      let installmentBruttoDay = this.installmentDay * 1.23;

      if (this.bikeprice) {
        return installmentBruttoDay.toFixed(2);
      } else {
        return "";
      }
    },
    installmentBruttoMonth: function () {
      let installmentBruttoMonth = this.installmentMonth * 1.23;
      if (this.bikeprice) {
        return installmentBruttoMonth.toFixed(2);
      } else {
        return "";
      }
    },
  },
  methods: {
    shorturl: function(str, n){
      return (str.length > n) ? str.slice(0, n-1) + '...' : str;
    },
    checkurl: function(url) {
      if (!url.startsWith('https://')) {
        url = 'https://' + url;
    }
    return url;
    },
    toggleForm() {
      this.showForm = !this.showForm;
    },
    scrollToCalc: function () {
      var anchor = document.querySelector("#anchor");
      anchor.scrollIntoView({
        behavior: "smooth", // Defines the transition animation. default: auto

      });
    },
    menutoggle: function () {
      console.log(this);
      var element = document.getElementById("hamburgero");
      var menu = document.getElementById("menu");
      if (element.classList.contains("is-active")) {
        element.classList.remove("is-active");
        menu.classList.remove("active");
      } else {
        menu.classList.add("active");
        element.classList.add("is-active");
      }
    },
    async beforeDownload() {
      //   await html2pdf()
      //     .set(options)
      //     .from(pdfContent)
      //     .toPdf()
      //     .get("pdf")
      //     .then((pdf) => {
      //       const totalPages = pdf.internal.getNumberOfPages();
      //       for (let i = 1; i <= totalPages; i++) {
      // pdf.addFont("./fonts/BNPPSans.ttf", "BNPPSans", "normal");
      // pdf.addFont("ComicSansMS", "Comic Sans", "normal");
      // this.addFileToVFS('BNPPSans-normal.ttf', font);
      // this.addFont('BNPPSans-normal.ttf', 'BNPPSans', 'normal');
      // pdf.addFileToVFS('BNPPSans.ttf', BNPPSans)
      // pdf.addFileToVFS('BNPPSans-Bold', BNPPSansBold)
      // pdf.addFont('BNPPSans.ttf', 'BNPPSans', 'normal')
      // pdf.addFont('BNPPSans.ttf', 'BNPPSansBold', 'bold')
      // pdf.setFont("Comic Sans");
      // pdf.text(650, 650, "Hello World");
      // pdf.setPage(i);
      // pdf.setFontSize(10);
      // pdf.setTextColor(150);
      // pdf.text(
      //   "Page " + i + " of " + totalPages,
      //   pdf.internal.pageSize.getWidth() * 0.88,
      //   pdf.internal.pageSize.getHeight() - 0.3
      // );
      //       }
      //     })
    },

    num_pmt(
      rate_per_period,
      number_of_payments,
      present_value,
      future_value,
      type
    ) {
      future_value = typeof future_value !== "undefined" ? future_value : 0;
      type = typeof type !== "undefined" ? type : 0;

      if (rate_per_period != 0.0) {
        // Interest rate exists
        var q = Math.pow(1 + rate_per_period, number_of_payments);
        return (
          -(rate_per_period * (future_value + q * present_value)) /
          ((-1 + q) * (1 + rate_per_period * type))
        );
      } else if (number_of_payments != 0.0) {
        // No interest rate, but number of payments exists
        return -(future_value + present_value) / number_of_payments;
      }

      return 0;
    },

    /*
            Generate Report using refs and calling the
            refs function generatePdf()
        */

    onProgress() {
      console.log("PROGRES");
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
};
</script>

<style>
/* stylelint-disable */
@font-face {
  font-family: "BNPPSansExtraBold";
  src: url("./fonts/BNPPSans-ExtraBold.woff2") format("woff2"),
    url("./fonts/BNPPSans-ExtraBold.woff") format("woff"),
    url("./fonts/BNPPSans-ExtraBold.svg#BNPPSans-ExtraBold") format("svg");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "BNPPSansBold";
  src: url("./fonts/BNPPSans-Bold.woff2") format("woff2"),
    url("./fonts/BNPPSans-Bold.woff") format("woff"),
    url("./fonts/BNPPSans-Bold.svg#BNPPSans-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "BNPPSansBoldItalic";
  src: url("./fonts/BNPPSans-BoldItalic.woff2") format("woff2"),
    url("./fonts/BNPPSans-BoldItalic.woff") format("woff"),
    url("./fonts/BNPPSans-BoldItalic.svg#BNPPSans-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "BNPPSansCondensedExtraBold";
  src: url("./fonts/BNPPSansCondensed-ExtraBold.woff2") format("woff2"),
    url("./fonts/BNPPSansCondensed-ExtraBold.woff") format("woff"),
    url("./fonts/BNPPSansCondensed-ExtraBold.svg#BNPPSansCondensed-ExtraBold")
      format("svg");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "BNPPSansCondensed";
  src: url("./fonts/BNPPSansCondensed.woff2") format("woff2"),
    url("./fonts/BNPPSansCondensed.woff") format("woff"),
    url("./fonts/BNPPSansCondensed.svg#BNPPSansCondensed") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BNPPSansExtraBoldItalic";
  src: url("./fonts/BNPPSansExtraBold-Italic.woff2") format("woff2"),
    url("./fonts/BNPPSansExtraBold-Italic.woff") format("woff"),
    url("./fonts/BNPPSansExtraBold-Italic.svg#BNPPSansExtraBold-Italic")
      format("svg");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "BNPPSansLightItalic";
  src: url("./fonts/BNPPSansLight-Italic.woff2") format("woff2"),
    url("./fonts/BNPPSansLight-Italic.woff") format("woff"),
    url("./fonts/BNPPSansLight-Italic.svg#BNPPSansLight-Italic") format("svg");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "BNPPSansLight";
  src: url("./fonts/BNPPSans-Light.woff2") format("woff2"),
    url("./fonts/BNPPSans-Light.woff") format("woff"),
    url("./fonts/BNPPSans-Light.svg#BNPPSansLight") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "BNPPSansCondensedBold";
  src: url("./fonts/BNPPSansCondensed-Bold.woff2") format("woff2"),
    url("./fonts/BNPPSansCondensed-Bold.woff") format("woff"),
    url("./fonts/BNPPSansCondensed-Bold.svg#BNPPSansCondensed-Bold")
      format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "BNPPSans";
  src: url("./fonts/BNPPSans.woff2") format("woff2"),
    url("./fonts/BNPPSans.woff") format("woff"),
    url("./fonts/BNPPSans.svg#BNPPSans") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "BNPPSansItalic";
  src: url("./fonts/BNPPSans-Italic.woff2") format("woff2"),
    url("./fonts/BNPPSans-Italic.woff") format("woff"),
    url("./fonts/BNPPSans-Italic.svg#BNPPSans-Italic") format("svg");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "BNPPSansCondensedLight";
  src: url("./fonts/BNPPSansCondensed-Light.woff2") format("woff2"),
    url("./fonts/BNPPSansCondensed-Light.woff") format("woff"),
    url("./fonts/BNPPSansCondensed-Light.svg#BNPPSansCondensed-Light")
      format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "BNPPSansLight";
  src: url("./fonts/BNPPSans-Light.woff2") format("woff2"),
    url("./fonts/BNPPSans-Light.woff") format("woff"),
    url("./fonts/BNPPSans-Light.svg#BNPPSans-Light") format("svg");
  font-weight: 300;
  font-style: normal;
} ;
</style>
