var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[(_vm.hiddenPickers)?_c('div',{staticClass:"pickers",class:[_vm.picked]},[_c('div',{staticClass:"clearbutton lex",on:{"click":_vm.setHighCompany}},[_vm._m(0)]),_c('div',{staticClass:"clearbutton rex",on:{"click":_vm.setLowCompany}},[_vm._m(1)])]):_vm._e(),(_vm.nothiddenpickers)?_c('div',{staticClass:"mainform"},[_c('div',{staticClass:"formx"},[_c('Vueform',{attrs:{"endpoint":"/sxf/","method":"post","sync":""},on:{"success":_vm.handleSuccess,"error":_vm.handleError},model:{value:(_vm.data),callback:function ($$v) {_vm.data=$$v},expression:"data"}},[_c('GroupElement',{attrs:{"name":"company_information","label":""}},[_c('TextElement',{attrs:{"name":"mojafirma","label":"Nazwa firmy","rules":"required","columns":12}})],1),_c('GroupElement',{attrs:{"name":"personal_information","label":""}},[_c('TextElement',{attrs:{"name":"firstname","label":"Imię","rules":"required","columns":6}}),_c('TextElement',{attrs:{"name":"lastname","label":"Nazwisko","rules":"required","columns":6}})],1),_c('GroupElement',{attrs:{"name":"more_informations","label":""}},[_c('TextElement',{attrs:{"name":"email","label":"Twój adres e-mail*","rules":"required|email|max:255","columns":6,"debounce":300}}),_c('TextElement',{attrs:{"name":"phone","label":"Twój numer telefonu","rules":"required","columns":6}})],1),_c('GroupElement',{attrs:{"name":"additional_information","label":""}},[_c('TextareaElement',{attrs:{"name":"message","label":"Masz dodatkowe pytania? Napisz","rows":"3","columns":12}})],1),_c('GroupElement',{attrs:{"name":"container"}},[_c('GroupElement',{attrs:{"name":"agreements","columns":{
  xs: { container: 12, label: 12, wrapper: 12 },
  sm: { container: 12, label: 12, wrapper: 12 },
  md: 12,
  lg: { container: 10, label: 2, wrapper: 10 }
}}},[_c('CheckboxElement',{attrs:{"name":"zgoda_przetwarzanie","text":"WYRAŻAM ZGODĘ NA PRZETWARZANIE PRZEZ ARVAL SERVICE LEASE POLSKA SP. Z O.O. Z SIEDZIBĄ PRZY UL. WOŁOSKIEJ 24,\n02-675 W WARSZAWIE MOICH DANYCH OSOBOWYCH W CELU I ZAKRESIE KONIECZNYM DO PRZEDSTAWIENIA OFERTY\nMARKETINGOWEJ PRODUKTÓW I USŁUG WŁASNYCH."}}),_c('CheckboxElement',{attrs:{"name":"zgoda_oferty","text":"WYRAŻAM ZGODĘ NA PRZEDSTAWIANIE W IMIENIU ARVAL SERVICE LEASE POLSKA SP. Z O.O., DROGĄ TELEFONICZNĄ INFORMACJI\nDOTYCZĄCYCH OFERT LUB PROMOCJI PRODUKTÓW I USŁUG ARVAL SERVICE LEASE POLSKA SP. Z O.O., ZGODNIE Z USTAWĄ\nZ DNIA 16 LIPCA 2004 R. PRAWO TELEKOMUNIKACYJNE (TJ. DZ.U. 2014 R. POZ. 243 Z PÓŹN. ZM.)."}}),_c('CheckboxElement',{attrs:{"name":"zgoda_mailing","text":"WYRAŻAM ZGODĘ NA OTRZYMYWANIE DROGĄ ELEKTRONICZNĄ INFORMACJI DOTYCZĄCYCH NOWYCH PRODUKTÓW, USŁUG,\nPROMOCJI ARVAL SERVICE LEASE POLSKA SP. Z O.O. STANOWIĄCYCH INFORMACJE HANDLOWE ZGODNIE Z USTAWĄ\nZ DNIA 18 LIPCA 2002 R. O ŚWIADCZENIU USŁUG DROGĄ ELEKTRONICZNĄ (TJ. DZ.U. 2013, POZ. 1422, Z PÓŹN. ZM.)."}}),_c('CheckboxElement',{attrs:{"rules":"required","name":"polityka_prywatnosci","text":"<strong>PRZECZYTAŁEM/AM I ROZUMIEM POLITYKĘ PRYWATNOŚCI *</strong>"}})],1),_c('GroupElement',{attrs:{"name":"submits","columns":2}},[_c('ButtonElement',{attrs:{"name":"submit","button-class":['subbuton'],"submits":""}},[_vm._v(" Wyślij ")])],1)],1),_c('GroupElement',{attrs:{"name":"privacy_policy"}},[_c('StaticElement',{class:[_vm.shomorelessclass],attrs:{"name":"html","content":_vm.content_of_agreeement}}),_c('ButtonElement',{attrs:{"button-class":['showmorebutton', _vm.bshowed],"onClick":_vm.showmoreorless,"name":"moreless","button-label":_vm.buttonlabelmoreless,"secondary":true}})],1)],1)],1)]):_vm._e(),(_vm.tnaknkyou)?_c('div',{staticClass:"thankyou"},[_c('h2',[_vm._v("Dziękujemy za przesłanie zgłoszenia.")])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',[_c('span',[_vm._v("Firma "),_c('strong',[_vm._v("powyżej 10 samochodów")]),_vm._v(" we flocie.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',[_c('span',[_vm._v("Firma "),_c('strong',[_vm._v("poniżej 10 samochodów")]),_vm._v(" we flocie, działalności gospodarcze. ")])])
}]

export { render, staticRenderFns }